// consumption la phi tieu thu nang luong theo khu vuc
// emission_factor la he so phat thai
import { evaluate } from 'mathjs'
export const emissionFor1m2 = (emission_factor, consumption) => {
  if (!consumption) return 0
  return evaluate(`((${emission_factor} * 1000 / 9.76 * ${consumption} * 0.51) + (0.0498 * ${consumption} * 0.21) + (0.059 * ${consumption} * 0.1) + (0.0678 * ${consumption} * 0.18)) / 1000`)
}

export const emissionForHourUnit = (emission_factor) => {
  if (!emission_factor) return 0
  return evaluate(`522 * ${emission_factor} / 9.76`)
}

export const emissionContinuousOperation = (emission_factor) => {
  if (!emission_factor) return 0
  return evaluate(`801 * ${emission_factor} / 9.76`)
}
